import { handleCatch } from '@/utils'
import { ApiCode } from '@/const/api'
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    admissionAspirations: [],
    totalRows: 0,
    maxOrderNo: 0,
  },
  getters: {
    admissionAspirations: state => state.admissionAspirations,
    totalRows: state => state.totalRows,
    maxOrderNo: state => state.maxOrderNo,
  },
  mutations: {
    SET_ADMISSION_ASPIRATIONS: (state, { total, records }) => {
      state.totalRows = total
      state.admissionAspirations = records
    },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => {
      state.maxOrderNo = maxOrderNo
    },
  },
  actions: {
    async getAdmissionAspirations({ commit }, params) {
      try {
        const response = await axios.get('/admissions/aspiration_information', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ADMISSION_ASPIRATIONS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getReportAdmissionAspirations({ commit }, params) {
      try {
        const response = await axios.get('/admissions/aspiration_information/get_report', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async createAspirations({ commit }, params) {
      try {
        const response = await axios.post('/admissions/aspiration_information', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAspirations({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/aspiration_information/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAspirations({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/aspiration_information/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get('/admissions/aspiration_information/maxOrderNo', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_MAX_ORDER_NO', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line consistent-return
    async checkDuplicateAspiration({ commit }, params) {
      try {
        const response = await axios.post('/admissions/aspiration_information/checkDuplicateAspiration', params)
        const { data } = response
        return { check: data.data }
      } catch (e) {
        handleCatch(e)
      }
    },
    async checkCriteriaSet({ commit }, params) {
      try {
        const response = await axios.post('/admissions/aspiration_information/checkCriteriaSet', params)
        const { data } = response
        return { checkCriteria: data.data }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
