import { ResourceCode } from '@/const/code'

export default [
  {
    path: '/applicantStudent',
    name: 'applicantStudent',
    component: () => import('@/views/applicant-student/UsersEdit.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Hồ sơ xét tuyển',
          active: false,
        },
      ],
      action: 'READ',
      resource: ResourceCode.ADMISSION_APPLICANT_STUDENT,
    },
  },
  {
    path: '/examination/exchangeScore',
    name: 'exchangeScore',
    component: () => import('@/views/exchange-score/Index.vue'),
    meta: {
      pageTitle: 'Quy đổi điểm từ chứng chỉ',
      breadcrumb: [
        {
          text: 'Hồ sơ xét tuyển',
          active: true,
        },
        {
          text: 'Quy đổi điểm chứng chỉ',
          active: false,
        },
      ],
      action: 'READ',
      resource: ResourceCode.EXCHANGE_SCORE,
    },
  },
  {
    path: '/examination/certifications',
    name: 'admissionCertifications',
    component: () => import('@/views/certificate/Index.vue'),
    meta: {
      pageTitle: 'Danh sách chứng chỉ',
      breadcrumb: [
        {
          text: 'Hồ sơ xét tuyển',
          active: true,
        },
        {
          text: 'Danh sách chứng chỉ',
          active: false,
        },
      ],
      action: 'READ',
      resource: ResourceCode.ADMISSION_CERTIFICATIONS,
    },
  },
]
