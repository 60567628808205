import { handleCatch } from '@/utils'
import { ApiCode } from '@/const/api'
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    admissionRegisterTests: [],
    totalRows: 0,
  },
  getters: {
    admissionRegisterTests: state => state.admissionRegisterTests,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_ADMISSION_REGISTER_TESTS: (state, { total, records }) => {
      state.totalRows = total
      state.admissionRegisterTests = records
    },
  },
  actions: {
    async getAdmissionRegisterTests({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_register_test', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ADMISSION_REGISTER_TESTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async create({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_register_test', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async update({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_register_test/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async delete({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_register_test/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line consistent-return
    async checkDuplicateRegisterTest({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_register_test/checkDuplicateRegisterTest', params)
        const { data } = response
        return { check: data.data }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
