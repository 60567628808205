<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col cols="12">
              <vue-good-table
                :columns="columns"
                :rows="dataSources"
                style-class="vgt-table striped bordered"
                row-style-class="vgt-row"
                :line-numbers="true"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'mediaUrl'">
                    <b-img
                      class="certificate-img"
                      fluid
                      :src="props.row.mediaUrl"
                      alt="Image 1"
                    />
                  </span>
                  <span v-else-if="props.column.field === 'status'">
                    {{getStatusName(props.row.status)}}
                  </span>
                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCol, BRow, BOverlay, BCard, BContainer, BImg,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import { ADMISSION_CONFIRM_CERTIFICATE_STATUSES } from '@/const/status'
export default {
  name: 'FormBuilding',
  components: {
    BCol,
    BRow,
    VueGoodTable,
    BOverlay,
    BCard,
    BContainer,
    BImg,
  },
  props: {
    dataBuilding: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isLoading: false,
      optionsStatus: [],
      paginationOptions: {
        enabled: true,
      },
      user: getUser(),
      columns: [
        {
          label: 'Chứng chỉ',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ảnh',
          field: 'mediaUrl',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      dataSources: [],
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
    }),
  },
  async created() {
    // init data FROM API
    await this.getInit()
  },
  methods: {
    ...mapActions({
      getAdmissionCertification: 'admissionCertificate/getAdmissionCertification',
    }),

    async getInit() {
      const params = {
        admissionRoundId: this.user.admissionRoundId,
        identification: this.user.identification,
      }
      const response = await this.getAdmissionCertification(params)
      this.dataSources = response
    },

    // handle even
    getStatusName(status) {
      return ADMISSION_CONFIRM_CERTIFICATE_STATUSES.find(item => item.value === status).label
    },
  },
}
</script>

<style type="text/css">
.certificate-img {
  display: block;
  max-width: 100px;
  max-height: 100px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 2px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.certificate-img:hover {
  transform: scale(5);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}
.vgt-responsive {
  overflow-x: visible !important;
}
</style>
