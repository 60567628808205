<template>
  <validation-observer
    ref="changePasswordFormRef"
    #default="{invalid}"
  >
    <b-form>
      <b-form-group label-for="oldPassword">
        <template v-slot:label>
          Mật khẩu cũ <span class="text-danger">*</span>
        </template>
        <validation-provider
          #default="{ errors }"
          name="Mật khẩu cũ"
          rules="required"
        >
          <b-form-input
            id="oldPassword"
            v-model="oldPassword"
            type="password"
            :state="getElementState(errors)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group label-for="newPassword">
        <template v-slot:label>
          Mật khẩu mới <span class="text-danger">*</span>
        </template>
        <validation-provider
          #default="{ errors }"
          name="Mật khẩu mới"
          vid="password"
          rules="required|min:8|password"
        >
          <b-form-input
            id="newPassword"
            v-model="newPassword"
            type="password"
            :state="getElementState(errors)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group label-for="reNewPassword">
        <template v-slot:label>
          Nhập lại mật khẩu mới <span class="text-danger">*</span>
        </template>
        <validation-provider
          #default="{ errors }"
          name="Nhập lại mật khẩu mới"
          rules="required|confirmed:password"
        >
          <b-form-input
            id="reNewPassword"
            v-model="reNewPassword"
            type="password"
            :state="getElementState(errors)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid"
          @click.prevent="handleChangePassword"
        >
          Cập nhật
        </b-button>
      </b-col>
    </b-form>
    <b-overlay
      no-wrap
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      :show="isLoading"
    />
  </validation-observer>

</template>

<script>
import {
  BCol, BFormInput, BFormGroup, BForm, BButton, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { confirmed, min, required } from '@core/utils/validations/validations'

export default {
  name: 'ChangePassword',
  components: {
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      oldPassword: '',
      newPassword: '',
      reNewPassword: '',
      required,
      confirmed,
      min,
    }
  },
  methods: {
    ...mapActions({
      changePassword: 'account/changePassword',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async handleChangePassword() {
      const valid = this.$refs
        .changePasswordFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const res = await this.changePassword({
            id: this.user.accountId,
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          })
          if (res.isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thay đổi mật khẩu thành công',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide('changePasswordModal')
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
