import { ResourceCode } from '@/const/code'

export default [
  {
    path: '/examination/registerTest',
    name: 'registerTest',
    component: () => import('@/views/register-test/Index.vue'),
    meta: {
      pageTitle: 'Phiếu đăng ký thi',
      breadcrumb: [
        {
          text: 'Thông tin kỳ thi',
          active: true,
        },
        {
          text: 'Phiếu đăng ký thi',
          active: false,
        },
      ],
      action: 'READ',
      resource: ResourceCode.REGISTER_TEST,
    },
  },
  {
    path: '/examination/examSchedule',
    name: 'examSchedule',
    component: () => import('@/views/exam-schedule/ExamSchedule.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Thông tin kỳ thi',
          active: true,
        },
        {
          text: 'Lịch thi',
          active: false,
        },
      ],
      action: 'READ',
      resource: ResourceCode.ADMISSION_EXAM_SCHEDULE,
    },
  },
  {
    path: '/examination/examScore',
    name: 'examScore',
    component: () => import('@/views/score/ExamScore.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Thông tin kỳ thi',
          active: true,
        },
        {
          text: 'Điểm thi',
          active: false,
        },
      ],
      action: 'READ',
      resource: ResourceCode.ADMISSION_EXAM_SCORE,
    },
  },
]
