import { handleCatch } from '@/utils'
import { ApiCode } from '@/const/api'
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    admissionExchangeScores: [],
    totalRows: 0,
    admissionLanguageCertificateByAdmissionFormIds: [],
    admissionSubjectIds: [],
    scoreCertificate: [],
    exchangeScoreCertificate: [],
    admissionScoreTypes: [],
    academicTranscriptScores: [],
  },
  getters: {
    admissionExchangeScores: state => state.admissionExchangeScores,
    totalRows: state => state.totalRows,
    admissionLanguageCertificateByAdmissionFormIds: state => state.admissionLanguageCertificateByAdmissionFormIds,
    admissionSubjectIds: state => state.admissionSubjectIds,
    scoreCertificate: state => state.scoreCertificate,
    exchangeScoreCertificate: state => state.exchangeScoreCertificate,
    admissionScoreTypes: state => state.admissionScoreTypes,
    academicTranscriptScores: state => state.academicTranscriptScores,
  },
  mutations: {
    SET_ADMISSION_LANGUAGE_CERTIFICATE: (state, admissionLanguageCertificateByAdmissionFormIds) => {
      state.admissionLanguageCertificateByAdmissionFormIds = admissionLanguageCertificateByAdmissionFormIds
    },
    SET_ADMISSION_SUBJECT: (state, admissionSubjectIds) => {
      state.admissionSubjectIds = admissionSubjectIds
    },
    SET_SCORE_CERTIFICATE: (state, scoreCertificate) => {
      state.scoreCertificate = scoreCertificate
    },
    SET_EXCHANGE_SCORE_CERTIFICATE: (state, exchangeScoreCertificate) => {
      state.exchangeScoreCertificate = exchangeScoreCertificate
    },
    SET_ADMISSION_REGISTER_TESTS: (state, { total, records }) => {
      state.totalRows = total
      state.admissionExchangeScores = records
    },
    SET_ADMISSION_SCORE_TYPES: (state, admissionScoreTypes) => { state.admissionScoreTypes = admissionScoreTypes },
    SET_ACADEMIC_TRANSCRIPT_SCORE: (state, academicTranscriptScores) => { state.academicTranscriptScores = academicTranscriptScores },
  },
  actions: {
    async getAdmissionScoreTypes({ commit }) {
      try {
        const response = await axios.get('uni/factAdmissionScoreTypes/getAll')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_ADMISSION_SCORE_TYPES', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAdmissionLanguageCertificates({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_priority_score/getLanguageCertificateByAdmissionFormId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.admissionLanguageCertificateId,
            label: item.admissionLanguageCertificateName,
          }))
          commit('SET_ADMISSION_LANGUAGE_CERTIFICATE', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getAdmissionSubjects({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_priority_score/getSubjectByLanguageCertificate', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.admissionSubjectId,
            label: item.admissionSubjectName,
          }))
          commit('SET_ADMISSION_SUBJECT', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getScoreCertificate({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_language_certificates/getScore', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SCORE_CERTIFICATE', data.data[0].scoreCertificate)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getExchangeScore({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_priority_score/getExchangeScore', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_EXCHANGE_SCORE_CERTIFICATE', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getAcademicTranscriptScore({ commit }, params) {
      try {
        const response = await axios.get('/admissions/academic_transcript_scores/getAcademicTranscriptScore', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_ACADEMIC_TRANSCRIPT_SCORE', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getAdmissionExchangeScores({ commit }, params) {
      try {
        const response = await axios.get('/admissions/academic_transcript_scores/getExchangeScore', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ADMISSION_REGISTER_TESTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async create({ commit }, params) {
      try {
        const response = await axios.post('/admissions/academic_transcript_scores', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async update({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/academic_transcript_scores/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async checkAcademicTranscriptScore({ commit }, params) {
      try {
        const response = await axios.post('/admissions/academic_transcript_scores/checkAcademicTranscriptScore', params)
        const { data } = response
        return { check: data.data }
      } catch (e) {
        handleCatch(e)
      }
    },
    async delete({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/academic_transcript_scores/exchangeScore/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
