<template>
  <div>
    <b-row class="match-height">
      <b-carousel
        id="carousel-interval"
        :interval="4000"
        controls
        indicators
        class="m-2"
        style="width: 100%; height: 500px;"
      >
        <b-carousel-slide
          v-for="image in urlCarousels"
          :key="image"
          :style="{ backgroundImage: `url(${image})`, backgroundSize: '100% 100%', backgroundPosition: 'center center', height: '500px' }"
        ></b-carousel-slide>
      </b-carousel>
    
    </b-row>
    <b-row>
      <h1 class="title-admission">MỐC THỜI GIAN TRONG ĐỢT TUYỂN SINH</h1>
    </b-row>
    <b-row class="mt-3">
      <b-col
        cols="12"
      >
        <note-card />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        v-for="item in meetups"
        :key="item.id"
        lg="4"
        md="6"
        sm="12"
        class="mb-2"
      >
        <admission-form-card
          :admission-form-title="item.title.admissionFormTitle"
          :admission-form-sub-title="item.title.admissionFormSubTitle"
          :data-form-card="item"
        />
      </b-col>
    </b-row>
    <b-row>
      <h1 class="title-admission">CÁC NGÀNH TUYỂN SINH</h1>
    </b-row>
    <b-row>
      <b-col lg="12" md="6" sm="12" class="mb-2 mt-2">
        <admission-form-card :data-form-card="meetups[0]" />
      </b-col>
    </b-row>
    <b-row class="text-center">
      <b-col cols="12">
        <h1 class="title-admission">THÔNG TIN LIÊN HỆ</h1>
        <b-card-text>
          Thông tin chi tiết vui lòng liên hệ chúng tôi!
        </b-card-text>
      </b-col>
    </b-row>
    <b-row class="text-center">
      <b-col
        sm="6"
        class="mt-1"
      >
        <b-card
          class="shadow-none py-1 faq-contact-card"
        >
          <b-avatar
            size="42"
            rounded
            variant="light-primary"
            class="mb-2"
          >
            <feather-icon
              icon="PhoneCallIcon"
              size="18"
            />
          </b-avatar>
          <h4>+ (84) 123456789</h4>
          <span class="text-body">We are always happy to help!</span>
        </b-card>
      </b-col>
      <b-col
        sm="6"
        class="mt-1"
      >
        <b-card
          class="shadow-none py-1 faq-contact-card"
        >
          <b-avatar
            size="42"
            rounded
            variant="light-primary"
            class="mb-2"
          >
            <feather-icon
              icon="MailIcon"
              size="18"
            />
          </b-avatar>
          <h4>hello@help.com</h4>
          <span class="text-body">Best way to get answer faster!</span>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BCarousel, BCarouselSlide, BRow, BCol, BAvatar } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AdmissionFormCard from '@/views/dashboard/AdmissionFormCard.vue'
import NoteCard from '@/views/dashboard/NoteCard.vue'

export default {
  components: {
    AdmissionFormCard,
    NoteCard,
    BCard,
    BLink,
    BCardCode,
    BAvatar,
    BCarousel,
    BCarouselSlide,
    BCardText,
    BRow,
    BCol
  },
  data() {
    return {
      isLoading: false,
      filter: {
        admissionRoundId: getUser().admissionRoundId,
        organization_id: getUser().orgId,
        currentPage: 1,
        itemsPerPage: 10,
        code: null,
        name: null,
        status: null,
      },
      urlCarousels: [],
      meetups: [],
      meetup: {
        title: [
          {
            admissionFormTitle: '',
            admissionFormSubTitle: '',
          }
        ],
        mediaData: [
          { avatar: 'CalendarIcon',
            title: '',
            subtitle: 'Thời gian mở - đóng đăng ký' },
          { avatar: 'MapPinIcon',
            title: '',
            subtitle: 'Chỉ tiêu tuyển sinh' },
        ],
      },
      meetupTemp: {},
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'dashboard/dataSourcesCarousel',
      dataAdmissionForms: 'dashboard/dataAdmissionForms',
    }),
  },
  async created() {
    await this.onLoad()
    if (this.dataSources.length > 0) {
      this.urlCarousels = this.dataSources.map(item => item.url)
    }
  },
  methods: {
    ...mapActions({
      getDataSources: 'dashboard/getDataSources',
      readAdmissionFormByAdmissionRoundId: 'dashboard/readAdmissionFormByAdmissionRoundId'
    }),
    
    async onLoad() {
      this.isLoading = true
      try {
        await this.getDataSourcesFromStore()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.setDataSources([])
      } finally {
        this.isLoading = false
      }
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getDataSources(this.filter)
        await this.readAdmissionFormByAdmissionRoundId(this.filter)
        if (this.dataAdmissionForms.length > 0) {
          this.meetups = [];
          this.dataAdmissionForms.forEach((item, key) => {
            let meetupTemp = {
              title: {
                admissionFormTitle: item.name,
                admissionFormSubTitle: item.admissionRoundName,
              },
              mediaData: [
                { avatar: 'CalendarIcon',
                  title: item.dateInfo,
                  subtitle: 'Thời gian mở - đóng đăng ký' },
                { avatar: 'MapPinIcon',
                  title: item.quota,
                  subtitle: 'Chỉ tiêu tuyển sinh' },
              ],
            };
            this.meetups.push(meetupTemp);
          });
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table';
.title-admission {
  align-content: center;
  color: #7367f0;
  font-weight: bold;
  margin: auto;
}
</style>
