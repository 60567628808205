var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" Đại học số - VNPT ")])],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Đăng ký tài khoản ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-form-group',{attrs:{"label-for":"organizationId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Trường tuyển sinh "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Trường tuyển sinh","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"organizationId","label":"name","options":_vm.organizations,"reduce":function (option) { return option.id; }},model:{value:(_vm.targetItem.organizationId),callback:function ($$v) {_vm.$set(_vm.targetItem, "organizationId", $$v)},expression:"targetItem.organizationId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Họ và tên","label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Họ và tên "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Họ và tên","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":_vm.getElementState(errors),"name":"name","placeholder":"Họ và tên"},model:{value:(_vm.targetItem.name),callback:function ($$v) {_vm.$set(_vm.targetItem, "name", $$v)},expression:"targetItem.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Số CCCD/CMND","label-for":"identification"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Số CCCD/CMND "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Số CCCD/CMND","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"identification","state":_vm.getElementState(errors),"name":"identification"},model:{value:(_vm.targetItem.identification),callback:function ($$v) {_vm.$set(_vm.targetItem, "identification", $$v)},expression:"targetItem.identification"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":_vm.getElementState(errors),"name":"email","placeholder":"mail@gmail.com"},model:{value:(_vm.targetItem.email),callback:function ($$v) {_vm.$set(_vm.targetItem, "email", $$v)},expression:"targetItem.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Số điện thoại","label-for":"phone"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Số điện thoại "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Số điện thoại","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":_vm.getElementState(errors),"name":"phone"},model:{value:(_vm.targetItem.phone),callback:function ($$v) {_vm.$set(_vm.targetItem, "phone", $$v)},expression:"targetItem.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mật khẩu "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Mật khẩu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":_vm.getElementState(errors),"name":"password","placeholder":"············"},model:{value:(_vm.targetItem.password),callback:function ($$v) {_vm.$set(_vm.targetItem, "password", $$v)},expression:"targetItem.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" Đăng ký ")])],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Bạn đã có tài khoản? ")]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v("Về trang đăng nhập!")])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }