<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <div>
      <b-media class="mb-2 ml-1">
        <b-row>
          <div class="d-flex flex-wrap">
            <b-col>
              <b-row class="mb-1">
                <b-avatar
                  size="76"
                  button
                  :src="userData.avatar"
                  variant="light-primary"
                  square
                  class="badge-minimal image"
                  badge-variant="success"
                  @click="handleClick"
                />
                <h4 class="ml-2 mb-1 text-center align-content-lg-center">
                  {{ userData.name }}
                </h4>
              </b-row>
              <b-row>
                <input
                  ref="inputFile"
                  class="input-file"
                  accept="image/*"
                  type="file"
                  @change="uploadFile"
                >
              </b-row>
              <br>
            </b-col>
          </div>
        </b-row>
      </b-media>
      
      <b-form>
        <div class="d-flex">
          <feather-icon
            icon="UserIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Thông tin cơ bản
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Họ và tên"
              label-for="name"
            >
              <template v-slot:label>
                Họ và tên <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Họ và tên"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="userData.name"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Ngày sinh"
              label-for="dob"
            >
              <template v-slot:label>
                Ngày sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ngày sinh"
                rules="required"
              >
                <flat-pickr
                  v-model="userData.dob"
                  class="form-control"
                  :config="{ dateFormat: 'd-m-Y'}"
                  placeholder="DD-MM-YYYY"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Số CCCD/CMND "
              label-for="identification"
            >
              <b-form-input
                id="identification"
                v-model="userData.identification"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Giới tính"
              label-for="genderId"
              label-class="mb-1"
            >
              <template v-slot:label>
                Giới tính <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Giới tính"
                rules="required"
              >
                <b-form-radio-group
                  id="genderId"
                  v-model="userData.genderId"
                  :options="dataListGenders"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <template v-slot:label>
                Email <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  type="email"
                  placeholder="nguyenvana@gmail.com"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Số điện thoại"
              label-for="phone"
            >
              <template v-slot:label>
                Số điện thoại <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số điện thoại"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="userData.phone"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Dân tộc"
              label-for="ethnicId"
            >
              <v-select
                v-model="userData.ethnicId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="dataListEthnics"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Tôn giáo"
              label-for="religionId"
            >
              <v-select
                v-model="userData.religionId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="dataListReligions"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group
              label="Ảnh CCCD/CMND mặt trước"
              label-for="file"
            >
              <template v-slot:label>
                Ảnh CCCD/CMND mặt trước <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ảnh CCCD/CMND mặt trước"
                rules="required"
              >
                <b-form-file
                  ref="inputFileFrontRef"
                  v-model="userData.identificationFrontMediaId"
                  :placeholder="userData.identificationFrontMediaName"
                  accept="image/*"
                  @change="uploadFront"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group
              label="Ảnh CCCD/CMND mặt sau"
              label-for="file"
            >
              <template v-slot:label>
                Ảnh CCCD/CMND mặt sau <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ảnh CCCD/CMND mặt sau"
                rules="required"
              >
                <b-form-file
                  ref="inputFileBackRef"
                  v-model="userData.identificationBackMediaId"
                  :placeholder="userData.identificationBackMediaName"
                  accept="image/*"
                  @change="uploadBack"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Địa chỉ thường trú
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Tỉnh/Thành phố"
              label-for="provinceCodePermanent"
            >
              <template v-slot:label>
                Tỉnh/Thành phố <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tỉnh/Thành phố"
                rules="required"
              >
                <v-select
                  id="provinceCodePermanent"
                  v-model="userData.provinceCodePermanent"
                  :options="dataListProvince"
                  :reduce="option => option.value"
                  @input="selectProvincePermanent"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Quận/Huyện"
              label-for="districtCodePermanent"
            >
              <template v-slot:label>
                Quận/Huyện <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Quận/Huyện"
                rules="required"
              >
                <v-select
                  id="districtCodePermanent"
                  v-model="userData.districtCodePermanent"
                  :options="dataListDistrictPermanent"
                  :reduce="option => option.value"
                  @input="selectDistrictPermanent"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Phường/Xã"
              label-for="wardCodePermanent"
            >
              <template v-slot:label>
                Phường/Xã <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Phường/Xã"
                rules="required"
              >
                <v-select
                  id="wardCodePermanent"
                  v-model="userData.wardCodePermanent"
                  label="label"
                  :options="dataListWardPermanent"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Địa chỉ cụ thể"
              label-for="addressPermanent"
            >
              <template v-slot:label>
                Địa chỉ cụ thể <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Địa chỉ cụ thể"
                rules="required"
              >
                <b-form-input
                  id="addressPermanent"
                  v-model="userData.addressPermanent"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Địa chỉ liên hệ
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Tỉnh/Thành phố"
              label-for="provinceCodeCurrent"
            >
              <template v-slot:label>
                Tỉnh/Thành phố <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tỉnh/Thành phố"
                rules="required"
              >
                <v-select
                  id="provinceCodeCurrent"
                  v-model="userData.provinceCodeCurrent"
                  label="label"
                  :options="dataListProvince"
                  :reduce="option => option.value"
                  @input="selectProvinceCurrent"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Quận/Huyện"
              label-for="districtCodeCurrent"
            >
              <template v-slot:label>
                Quận/Huyện <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Quận/Huyện"
                rules="required"
              >
                <v-select
                  id="districtCodeCurrent"
                  v-model="userData.districtCodeCurrent"
                  label="label"
                  :options="dataListDistrictCurrent"
                  :reduce="option => option.value"
                  @input="selectDistrictCurrent"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Phường/Xã"
              label-for="wardCodeCurrent"
            >
              <template v-slot:label>
                Phường/Xã <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Phường/Xã"
                rules="required"
              >
                <v-select
                  id="wardCodeCurrent"
                  v-model="userData.wardCodeCurrent"
                  label="label"
                  :options="dataListWardCurrent"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Địa chỉ cụ thể"
              label-for="addressCurrent"
            >
              <template v-slot:label>
                Địa chỉ cụ thể <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Địa chỉ cụ thể"
                rules="required"
              >
                <b-form-input
                  id="addressCurrent"
                  v-model="userData.addressCurrent"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Nơi học Trung học Phổ thông
          </h4>
        </div>
        <div class="d-flex mt-2">
          <h6
            class="mb-0 ml-50"
            style="color: #7367f0"
          >
            Lớp 10
          </h6>
        </div>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Tỉnh/Thành phố"
              label-for="provinceTenth"
            >
              <template v-slot:label>
                Tỉnh/Thành phố <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tỉnh/Thành phố"
                rules="required"
              >
                <v-select
                  id="provinceTenth"
                  v-model="userData.provinceTenth"
                  label="label"
                  :options="dataListProvince"
                  :reduce="option => option.value"
                  @input="selectProvinceTenth"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Quận/Huyện"
              label-for="districtTenth"
            >
              <template v-slot:label>
                Quận/Huyện <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Quận/Huyện"
                rules="required"
              >
                <v-select
                  id="districtTenth"
                  v-model="userData.districtTenth"
                  label="label"
                  :options="dataListDistrictTenth"
                  :reduce="option => option.value"
                  @input="selectDistrictTenth"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group
              label="Trường Trung học Phổ thông"
              label-for="highSchoolTenth"
            >
              <template v-slot:label>
                Trường Trung học Phổ thông <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trường Trung học Phổ thông"
                rules="required"
              >
                <v-select
                  id="highSchoolTenth"
                  v-model="userData.highSchoolTenth"
                  label="label"
                  :options="dataListHighSchoolTenth"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        
        </b-row>
        <div class="d-flex mt-2">
          <h6
            class="mb-0 ml-50"
            style="color: #7367f0"
          >
            Lớp 11
          </h6>
        </div>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Tỉnh/Thành phố"
              label-for="provinceEleventh"
            >
              <template v-slot:label>
                Tỉnh/Thành phố <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tỉnh/Thành phố"
                rules="required"
              >
                <v-select
                  id="provinceEleventh"
                  v-model="userData.provinceEleventh"
                  label="label"
                  :options="dataListProvince"
                  :reduce="option => option.value"
                  @input="selectProvinceEleventh"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Quận/Huyện"
              label-for="districtEleventh"
            >
              <template v-slot:label>
                Quận/Huyện <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Quận/Huyện"
                rules="required"
              >
                <v-select
                  id="districtEleventh"
                  v-model="userData.districtEleventh"
                  label="label"
                  :options="dataListDistrictEleventh"
                  :reduce="option => option.value"
                  @input="selectDistrictEleventh"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group
              label="Trường Trung học Phổ thông"
              label-for="highSchoolEleventh"
            >
              <template v-slot:label>
                Trường Trung học Phổ thông <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trường Trung học Phổ thông"
                rules="required"
              >
                <v-select
                  id="highSchoolEleventh"
                  v-model="userData.highSchoolEleventh"
                  :options="dataListHighSchoolEleventh"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2">
          <h6
            class="mb-0 ml-50"
            style="color: #7367f0"
          >
            Lớp 12
          </h6>
        </div>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Tỉnh/Thành phố"
              label-for="provinceTwelfth"
            >
              <template v-slot:label>
                Tỉnh/Thành phố <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tỉnh/Thành phố"
                rules="required"
              >
                <v-select
                  id="provinceTwelfth"
                  v-model="userData.provinceTwelfth"
                  label="label"
                  :options="dataListProvince"
                  :reduce="option => option.value"
                  @input="selectProvinceTwelfth"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Quận/Huyện"
              label-for="districtTwelfth"
            >
              <template v-slot:label>
                Quận/Huyện <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Quận/Huyện"
                rules="required"
              >
                <v-select
                  id="districtTwelfth"
                  v-model="userData.districtTwelfth"
                  label="label"
                  :options="dataListDistrictTwelfth"
                  :reduce="option => option.value"
                  @input="selectDistrictTwelfth"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group
              label="Trường Trung học Phổ thông"
              label-for="highSchoolTwelfth"
            >
              <template v-slot:label>
                Trường Trung học Phổ thông <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trường Trung học Phổ thông"
                rules="required"
              >
                <v-select
                  id="highSchoolTwelfth"
                  v-model="userData.highSchoolTwelfth"
                  :options="dataListHighSchoolTwelfth"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-button
        :disabled="!checkRegisterDate"
        type="submit"
        variant="primary"
        class="mr-1"
        @click="onSave"
      >
        Lưu
      </b-button>
    </div>
  </validation-observer>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BFormFile, BForm, BFormRadioGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { RANK_CERTIFICATE_TYPES } from '@/const/status'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    flatPickr,
    BFormFile,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      RANK_CERTIFICATE_TYPES,
      currentUser: getUser(),
      provinceCodePermanentTemp: null,
      provinceCodeCurrentTemp: null,
      provinceCodeTenthTemp: null,
      provinceCodeEleventhTemp: null,
      provinceCodeTwelfthTemp: null,
      dataListDistrictPermanent: [],
      dataListDistrictCurrent: [],
      dataListWardPermanent: [],
      dataListWardCurrent: [],
      dataListDistrictTenth: [],
      dataListDistrictEleventh: [],
      dataListDistrictTwelfth: [],
      dataListHighSchoolTenth: [],
      dataListHighSchoolEleventh: [],
      dataListHighSchoolTwelfth: [],
      ulrAvatar: '',
      ulrIdentificationFront: '',
      ulrIdentificationBack: '',
      mediaIds: [],
      mediaFrontIds: [],
      mediaBackIds: [],
      dataTemp: {},
      checkRegisterDate: false,
    }
  },
  computed: {
    ...mapGetters({
      dataListEthnics: 'applicantStudent/dataListEthnics',
      dataListReligions: 'applicantStudent/dataListReligions',
      dataListGenders: 'applicantStudent/dataListGenders',
      dataListProvince: 'applicantStudent/dataListProvince',
      dataListDistrict: 'applicantStudent/dataListDistrict',
      dataListWard: 'applicantStudent/dataListWard',
      dataListHighSchool: 'applicantStudent/dataListHighSchool',
      checkRegister: 'account/checkRegister'
    }),
  },
  async created() {
    await this.onLoad()
  },
  methods: {
    ...mapActions({
      getDataListEthnics: 'applicantStudent/getDataListEthnics',
      getDataListReligions: 'applicantStudent/getDataListReligions',
      getDataListGenders: 'applicantStudent/getDataListGenders',
      getDataListProvinces: 'applicantStudent/getDataListProvinces',
      getDataListDistricts: 'applicantStudent/getDataListDistricts',
      getDataListWards: 'applicantStudent/getDataListWards',
      getDataListHighSchools: 'applicantStudent/getDataListHighSchools',
      uploadAvatar: 'applicantStudent/uploadApplicantStudentAvatar',
      getLinkAvatar: 'applicantStudent/getLinkAvatar',
      updateApplicantStudent: 'applicantStudent/updateApplicantStudent',
      uploadIdentificationFront: 'applicantStudent/uploadIdentificationFront',
      uploadIdentificationBack: 'applicantStudent/uploadIdentificationBack',
      checkDateAdmissionRound: 'account/checkDateAdmissionRound',
    }),
    async selectProvincePermanent(provinceCode) {
      this.dataListDistrictPermanent = []
      await this.getDataListDistricts({ province_code: provinceCode })
      this.provinceCodePermanentTemp = provinceCode
      this.dataListDistrictPermanent = this.dataListDistrict
    },
    async selectDistrictPermanent(districtCode) {
      this.dataListWardPermanent = []
      await this.getDataListWards({
        province_code: this.provinceCodePermanentTemp,
        district_code: districtCode,
      })
      this.dataListWardPermanent = this.dataListWard
    },
    async selectProvinceCurrent(provinceCode) {
      this.dataListDistrictCurrent = []
      await this.getDataListDistricts({ province_code: provinceCode })
      this.provinceCodeCurrentTemp = provinceCode
      this.dataListDistrictCurrent = this.dataListDistrict
    },
    async selectDistrictCurrent(districtCode) {
      this.dataListWardCurrent = []
      await this.getDataListWards({
        province_code: this.provinceCodeCurrentTemp,
        district_code: districtCode,
      })
      this.dataListWardCurrent = this.dataListWard
    },
    async selectProvinceTenth(provinceCode) {
      this.dataListDistrictTenth = []
      await this.getDataListDistricts({ province_code: provinceCode })
      this.provinceCodeTenthTemp = provinceCode
      this.dataListDistrictTenth = this.dataListDistrict
    },
    async selectDistrictTenth(districtCode) {
      this.dataListHighSchoolTenth = []
      await this.getDataListHighSchools({
        province_code: this.provinceCodeTenthTemp || this.userData.provinceTenth,
        district_code: districtCode || this.userData.districtTenth,
      })
      this.dataListHighSchoolTenth = this.dataListHighSchool
    },
    async selectProvinceEleventh(provinceCode) {
      this.dataListDistrictEleventh = []
      await this.getDataListDistricts({ province_code: provinceCode })
      this.provinceCodeEleventhTemp = provinceCode
      this.dataListDistrictEleventh = this.dataListDistrict
    },
    async selectDistrictEleventh(districtCode) {
      this.dataListHighSchoolEleventh = []
      await this.getDataListHighSchools({
        province_code: this.provinceCodeEleventhTemp || this.userData.provinceEleventh,
        district_code: districtCode || this.userData.districtEleventh,
      })
      this.dataListHighSchoolEleventh = this.dataListHighSchool
    },
    async selectProvinceTwelfth(provinceCode) {
      this.dataListDistrictTwelfth = []
      await this.getDataListDistricts({ province_code: provinceCode })
      this.provinceCodeTwelfthTemp = provinceCode
      this.dataListDistrictTwelfth = this.dataListDistrict
    },
    async selectDistrictTwelfth(districtCode) {
      this.dataListHighSchoolTwelfth = []
      await this.getDataListHighSchools({
        province_code: this.provinceCodeTwelfthTemp || this.userData.provinceTwelfth,
        district_code: districtCode || this.userData.districtTwelfth,
      })
      this.dataListHighSchoolTwelfth = this.dataListHighSchool
    },
    async uploadFile(e) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ảnh đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      Array.from(files).forEach(file => {
        if (!allowedTypes.includes(file.type)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Chọn file có định dạng ảnh: JPEG, PNG, GIF, ...',
              icon: 'InfoIcon',
              variant: 'warning',
            },
          });
          return;
        }
        formData.append('files', file);
      });
      await this.$recaptchaLoaded()
      const recaptchaResponse = await this.$recaptcha('uploadAvatar')
      formData.append('recaptchaResponse', recaptchaResponse)
      const params = {
        id: this.userData.id,
        params: formData,
      }
      const response = await this.uploadAvatar(params)
      if (response.isSuccessful) {
        this.showToast('Cập nhật avatar thành công', 'CheckIcon', 'success')
        const resLink = await this.getLinkAvatar(response.data[0])
        if (resLink.isSuccessful) {
          this.ulrAvatar = resLink.data
          this.userData.avatar = this.ulrAvatar
        }
        this.mediaIds = response
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
      this.$refs.inputFile.value = null
    },
    async uploadFront(e) {
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ảnh đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }

      const countFileOverSize = this.checkMaxSizeUpload(files)
      if (countFileOverSize > 0) return
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      Array.from(files).forEach(file => {
        if (!allowedTypes.includes(file.type)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Chọn file có định dạng ảnh: JPEG, PNG, GIF, ...',
              icon: 'InfoIcon',
              variant: 'warning',
            },
          });
          return;
        }
        formData.append('files', file);
      });
      await this.$recaptchaLoaded()
      const recaptchaResponse = await this.$recaptcha('uploadIdentificationFront')
      formData.append('recaptchaResponse', recaptchaResponse)
      const params = {
        id: this.userData.id,
        params: formData,
      }
      const response = await this.uploadIdentificationFront(params)
      if (response.isSuccessful) {
        this.showToast('Cập nhật thành công', 'CheckIcon', 'success')
        this.mediaFrontIds = response
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
    },
    async uploadBack(e) {
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ảnh đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }

      const countFileOverSize = this.checkMaxSizeUpload(files)
      if (countFileOverSize > 0) return
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      Array.from(files).forEach(file => {
        if (!allowedTypes.includes(file.type)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Chọn file có định dạng ảnh: JPEG, PNG, GIF, ...',
              icon: 'InfoIcon',
              variant: 'warning',
            },
          });
          return;
        }
        formData.append('files', file);
      });
      await this.$recaptchaLoaded()
      const recaptchaResponse = await this.$recaptcha('uploadIdentificationBack')
      formData.append('recaptchaResponse', recaptchaResponse)
      const params = {
        id: this.userData.id,
        params: formData,
      }
      const response = await this.uploadIdentificationBack(params)
      if (response.isSuccessful) {
        this.showToast('Cập nhật thành công', 'CheckIcon', 'success')
        this.mediaIdBacks = response
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
    },

    checkMaxSizeUpload(files) {
      let listOverSize = 0
      files.forEach(file => {
        if (file.size > 0.5 * 1024 * 1024) {
          // eslint-disable-next-line no-plusplus
          listOverSize++
        }
      })

      if (listOverSize > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Dung lượng vượt quá 0.5M',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
      }
      return listOverSize
    },

    handleClick() {
      this.$refs.inputFile.click()
    },
    async onLoad() {
      this.isLoading = true
      try {
        await Promise.all([
          this.getDataListEthnics({}),
          this.getDataListReligions({}),
          this.getDataListGenders({}),
          this.getDataListProvinces({}),
          this.checkDateAdmissionRound(this.currentUser.admissionRoundId)
        ])
        this.checkRegisterDate = this.checkRegister
        
        await this.selectProvincePermanent(this.userData.provinceCodePermanent)
        await this.selectDistrictPermanent(this.userData.districtCodePermanent)

        await this.selectProvinceCurrent(this.userData.provinceCodeCurrent)
        await this.selectDistrictCurrent(this.userData.districtCodeCurrent)

        await this.selectProvinceTenth(this.userData.provinceTenth)
        await this.selectDistrictTenth(this.userData.districtTenth)

        await this.selectProvinceEleventh(this.userData.provinceEleventh)
        await this.selectDistrictEleventh(this.userData.districtEleventh)

        await this.selectProvinceTwelfth(this.userData.provinceTwelfth)
        await this.selectDistrictTwelfth(this.userData.districtTwelfth)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onSave() {
      this.isLoading = true
      try {
        const response = await this.updateApplicantStudent(this.userData)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.showToast('Cập nhật thành công', 'CheckIcon', 'success')
          } else {
            this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
