export default {
  // Endpoints
  loginEndpoint: '/auth/loginAdmission',
  refreshEndpoint: '/auth/refreshAdmission',
  logoutEndpoint: '/auth/logoutAdmission',
  verifyEndpoint: '/auth/verifyAdmission',
  verifySsoEndpoint: '/auth/verifyAdmission',
  forgotPassword: '/auth/forgotPasswordAdmission',
  verifyForgotPassword: '/auth/verifyForgotPasswordAdmission',
  resetPassword: '/auth/resetPasswordAdmission',
  tokenType: 'Bearer',
  storageTokenKeyName: 'qldtAccessTokenAdmission',
  storageRefreshTokenKeyName: 'qldtRefreshTokenAdmission',
  storageUserName: 'qldtUserAdmission',
}
