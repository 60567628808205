<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card
        class="mb-0"
      >
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            Đại học số - VNPT
          </h2>
        </b-link>
        <b-card-title class="mb-1">
          Đăng ký tài khoản
        </b-card-title>
        <validation-observer
          ref="registerForm"
          #default="{ invalid }"
        >
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <b-form-group label-for="organizationId">
              <template v-slot:label>
                Trường tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                v-slot="{ errors }"
                name="Trường tuyển sinh"
                rules="required"
              >
                <v-select
                  id="organizationId"
                  v-model="targetItem.organizationId"
                  label="name"
                  :options="organizations"
                  :reduce="option => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Họ và tên"
              label-for="name"
            >
              <template v-slot:label>
                Họ và tên <span class="text-danger">*</span>
              </template>
              <validation-provider
                v-slot="{ errors }"
                name="Họ và tên"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetItem.name"
                  :state="getElementState(errors)"
                  name="name"
                  placeholder="Họ và tên"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Identification -->
            <b-form-group
              label="Số CCCD/CMND"
              label-for="identification"
            >
              <template v-slot:label>
                Số CCCD/CMND <span class="text-danger">*</span>
              </template>
              <validation-provider
                v-slot="{ errors }"
                name="Số CCCD/CMND"
                rules="required"
              >
                <b-form-input
                  id="identification"
                  v-model="targetItem.identification"
                  :state="getElementState(errors)"
                  name="identification"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <template v-slot:label>
                Email <span class="text-danger">*</span>
              </template>
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="targetItem.email"
                  :state="getElementState(errors)"
                  name="email"
                  placeholder="mail@gmail.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Phone -->
            <b-form-group
              label="Số điện thoại"
              label-for="phone"
            >
              <template v-slot:label>
                Số điện thoại <span class="text-danger">*</span>
              </template>
              <validation-provider
                v-slot="{ errors }"
                name="Số điện thoại"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="targetItem.phone"
                  :state="getElementState(errors)"
                  name="phone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Password -->
            <b-form-group
              label="Password"
              label-for="password"
            >
              <template v-slot:label>
                Mật khẩu <span class="text-danger">*</span>
              </template>
              <validation-provider
                v-slot="{ errors }"
                name="Mật khẩu"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="targetItem.password"
                    :type="passwordFieldType"
                    :state="getElementState(errors)"
                    class="form-control-merge"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Submit Button -->
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="invalid"
            >
              Đăng ký
            </b-button>
          </b-form>
        </validation-observer>
        <b-card-text class="text-center mt-2">
          <span>Bạn đã có tài khoản? </span>
          <b-link :to="{ name: 'login' }">
            <span>Về trang đăng nhập!</span>
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm, BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import VSelect from 'vue-select'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import { Flag } from '@/const/flag'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    VSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      targetItem: {
        name: null,
        email: null,
        phone: null,
        identification: null,
        password: null,
        status: Flag.ACTIVE,
        orderNo: 1,
        organizationId: null,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      organizations: 'account/organizations',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getOrganizations()
      if (this.organizations.length === 1) {
        this.targetItem.organizationId = this.organizations[0].id
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      createApplicantStudent: 'account/createAccount',
      getOrganizations: 'account/getOrganizations',
    }),
    async onSave() {
      this.isLoading = true
      try {
        const response = await this.createApplicantStudent(this.targetItem)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$emit('succeed')
            this.$router.push('/login')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.onSave()
        }
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-auth.scss';
</style>
