import useJwt from '@/auth/jwt/useJwt'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { setRefreshToken, setToken, setUser } from '@/auth/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {
    SET_TOKEN(state, { accessToken, refreshToken }) {
      setToken(accessToken)
      setRefreshToken(refreshToken)
    },
    SET_USER(state, user) {
      setUser(user)
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await useJwt.login(credentials)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { accessToken, refreshToken } = data.data
          commit('SET_TOKEN', { accessToken, refreshToken })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async verify({ commit }) {
      try {
        const response = await useJwt.verify()
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { accessToken, refreshToken, user } = data.data
          commit('SET_TOKEN', { accessToken, refreshToken })
          commit('SET_USER', user)
          return JSON.parse(user)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async forgotPassword({ commit }, params) {
      try {
        const response = await useJwt.forgotPassword(params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async verifyForgotPassword({ commit }, params) {
      try {
        const response = await useJwt.verifyForgotPassword(params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS && data.data === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async resetPassword({ commit }, params) {
      try {
        const response = await useJwt.resetPassword(params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
          username: data.data,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
