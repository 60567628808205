<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <template v-if="isLoggedIn">
      <b-navbar-nav class="nav align-items-center ml-auto">
        <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content>
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name font-weight-bolder mb-0">
                {{ user ? user.name : '' }}
              </p>
            </div>
            <b-avatar
              size="40"
              variant="light-primary"
              badge
              :src="ulrAvatar"
              class="badge-minimal"
              badge-variant="success"
            />
          </template>

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="onLogout"
          >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            <span>Đăng xuất</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </template>
    <template v-else>
      <b-nav>
        <b-nav-item
          active
          :to="{ name: 'login' }"
        >
          Đăng nhập
        </b-nav-item>
      </b-nav>
    </template>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BNav, BNavItem,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { getUser, isUserLoggedIn, getLoginType } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { LOGIN_TYPE } from '@/const/type'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BNav,
    BNavItem,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      userInfo: {},
      ulrAvatar: '',
      isLoggedIn: isUserLoggedIn(),
    }
  },
  async created() {
    if (isUserLoggedIn()) {
      this.isLoading = true
      try {
        if (!this.user.avatar) {
          // eslint-disable-next-line global-require
          this.ulrAvatar = require('@/assets/images/avatars/default_avatar.jpg')
        } else {
          const resLink = await this.getLinkAvatar(this.user.avatar)
          if (resLink.isSuccessful) {
            this.ulrAvatar = resLink.data
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  methods: {
    onLogout() {
      this.isLoggedIn = false
      const loginType = getLoginType()
      if (loginType === LOGIN_TYPE.NORMAL) {
        useJwt.logoutNormal()
      } else {
        useJwt.logoutSso()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
