import { handleCatch } from '@/utils'
import { ApiCode } from '@/const/api'
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    aspirationInformations: [],
  },
  getters: {
    aspirationInformations: state => state.aspirationInformations,
  },
  mutations: {
    SET_ASPIRATION_INFORMATIONS: (state, aspirationInformations) => { state.aspirationInformations = aspirationInformations },
  },
  actions: {
    async getListAspirationInformation({ commit }, params) {
      try {
        const response = await axios.get('admissions/aspiration_information/getListAspirationInformation', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_ASPIRATION_INFORMATIONS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async confirmAdmission({ commit }, id) {
      try {
        const response = await axios.put(`admissions/applicant_students/${id}/confirmAdmission`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
