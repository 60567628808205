<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm môn đăng ký thi' : 'Cập nhật môn đăng ký thi'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="admissionFormId">
              <template v-slot:label>
                Phương thức tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Phương thức tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionFormId"
                  :options="dataListAdmissionForm"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="admissionSubjectId">
              <template v-slot:label>
                Môn thi <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Môn thi"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionSubjectId"
                  label="label"
                  :options="dataListSubject"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'

export default {
  name: 'Save',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        admissionRoundId: getUser().admissionRoundId,
        applicantStudentId: getUser().id,
        admissionSubjectId: null,
        status: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      dataListAdmissionForm: 'applicantStudent/dataListAdmissionForm',
      dataListSubject: 'applicantStudent/dataListSubject',
    }),
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      getDataListAdmissionForm: 'applicantStudent/getDataListAdmissionForm',
      getListSubject: 'applicantStudent/getListSubject',
      createAdmissionRegisterTest: 'registerTest/create',
      updateAdmissionRegisterTest: 'registerTest/update',
      checkDuplicateRegisterTest: 'registerTest/checkDuplicateRegisterTest',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await this.getListSubject()
      await this.getDataListAdmissionForm({ admissionRoundId: this.targetItem.admissionRoundId })
      if ( this.item ) {
        this.targetItem = { ...this.item }
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        admissionRoundId: getUser().admissionRoundId,
        applicantStudentId: getUser().id,
        admissionSubjectId: null,
        status: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if ( valid ) {
        this.isLoading = true
        const response = await this.checkDuplicateRegisterTest(this.targetItem)
        if (!response.check) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thí sinh đã đăng ký môn thi!',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          this.$bvModal.hide('saveModal')
          this.isLoading = false
        }
        else {
          try {
            const response = this.isCreated
              ? await this.createAdmissionRegisterTest(this.targetItem)
              : await this.updateAdmissionRegisterTest(this.targetItem)
            if ( response ) {
              const {
                isSuccessful,
                message
              } = response
              if ( isSuccessful ) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                if ( type === 'hide' ) {
                  this.$bvModal.hide('saveModal')
                }
                this.$emit('succeed')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch ( e ) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
