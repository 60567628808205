import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    academicTranscriptScore: [],
    totalRows: 0,
    admissionScoreTypes: [],
  },
  getters: {
    academicTranscriptScore: state => state.academicTranscriptScore,
    totalRows: state => state.totalRows,
    admissionScoreTypes: state => state.admissionScoreTypes,
  },
  mutations: {
    SET_ACADEMIC_TRANSCRIPT_SCORE: (state, { total, records }) => {
      state.totalRows = total
      state.academicTranscriptScore = records
    },
    SET_ADMISSION_SCORE_TYPES: (state, admissionScoreTypes) => {
      state.admissionScoreTypes = admissionScoreTypes
    },
  },
  actions: {
    async getAdmissionScoreTypes({ commit }) {
      try {
        const response = await axios.get('uni/factAdmissionScoreTypes/getAllByApplicantStudent')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_ADMISSION_SCORE_TYPES', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAcademicTranscriptScore({ commit }, params) {
      try {
        const response = await axios.get('/admissions/academic_transcript_scores', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ACADEMIC_TRANSCRIPT_SCORE', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async create({ commit }, params) {
      try {
        const response = await axios.post('/admissions/academic_transcript_scores', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async update({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/academic_transcript_scores/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async delete({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/academic_transcript_scores/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
