import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async create({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_conduct_and_academic_performance', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if ( isSuccessful ) {
          return {
            isSuccessful,
            message: data.message
          }
        }
        return {
          isSuccessful,
          message: data.debugMessage
        }
      } catch ( e ) {
        handleCatch(e)
      }
      return null
    },
  }
}
