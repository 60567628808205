<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <b-container>
      <div class="main news-list">
        <b-row>
          <b-col cols="12" >
            <HeaderNews @changeCategory="fnGetNews" />
          </b-col>
          <b-col cols="12" md="8">
            <b-row v-if="!showDetail && news.length > 0">
              <b-col
                v-for="item in news"
                :key="item.id"
                cols="12"
              >
                <b-card
                  no-body
                  class="overflow-hidden news-list-item"
                >
                  <b-row no-gutters>
                    <b-col cols="5" md="2">
                      <b-card-img
                        :src="item.pathMedia ? item.pathMedia : 'https://picsum.photos/400/400/?image=20'"
                        alt="Image"
                        class="rounded-1"
                      />
                    </b-col>
                    <b-col cols="7" md="10">
                      <b-card-body class="pb-1">
                        <h4>
                          <b-link :to="{ name: 'newsDetail', params: { newsId: item.id } }">
                            <span class="title-news">{{ item.title }}</span>
                          </b-link>
                        </h4>
                        <b-card-text class=" d-none d-md-block">
                          {{ item.header.split(" ", 30).join(" ") + " ..." }}
                        </b-card-text>
                      </b-card-body>
                      <b-card-footer class="p-0">
                        <b-card-text class="float-right mb-1 mr-1 mt-1">
                          {{item.createdAt}}
                        </b-card-text>
                      </b-card-footer>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12">
                <div class="pagi text-right">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="total"
                    :per-page="perPage"
                    aria-controls="my-table"
                    @input="(value) => onPageChange({ currentPage: value })"
                  ></b-pagination>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="showDetail">
              <NewsDetail :newsId="newsId" />
            </b-row>
          </b-col>
          <b-col cols="12" md="4">
            <SideBarNews :categoryId="categoryId" @showDetailNews="showDetailNews"/>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </b-overlay>
</template>

<script>
import {
  BImg,
  BCard,
  BCardImg,
  BCardText,
  BCardBody,
  BCardHeader,
  BCol,
  BRow,
  BContainer,
  BLink,
  BBreadcrumb,
  BBreadcrumbItem,
  BCardFooter,
  BOverlay,
  BPagination
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SideBarNews from '@/views/admission-info/SideBarNews.vue'
import HeaderNews from '@/views/admission-info/HeaderNews.vue'
import NewsDetail from '@/views/admission-info/NewsDetail.vue'
import router from '@/router'
import { getUser } from '@/auth/utils'

export default {
  name: 'University',
  components: {
    BImg,
    BCard,
    BCardImg,
    BCardText,
    BCardBody,
    BCardHeader,
    BCol,
    BRow,
    BContainer,
    BLink,
    BPagination
    ,
    BBreadcrumb,
    BBreadcrumbItem,
    BCardFooter,
    BOverlay,
    SideBarNews,
    HeaderNews,
    NewsDetail
  },
  data() {
    return {
      items: [
        {
          text: 'Danh mục',
          href: '#',
        },
        {
          text: 'Tất cả',
          href: '#',
          active: true,
        },
      ],
      newsHot: [],
      newsLatest: [],
      currentCategory: 'Tất cả',
      categoryId: 0,
      isLoading: true,
      showDetail: false,
      newsId: 0,
      currentPage: 1,
      perPage: 10,
      param: '',
      user: getUser(),
    }
  },
  
  async created() {
    await this.getCategory()
  },
  
  computed: {
    ...mapGetters({
      news: 'article/articles',
      newsHL: 'article/articlesHL',
      total: 'article/total',
      category: 'article/category',
    }),
  },
  watch: {
    '$route.params.newsId': {
      handler: async function (val) {
        await this.getInitData(0, val)
      },
      deep: true,
      immediate: true,
    },
  },
  beforeRouteUpdate(to, from, next) {
    next()
  },
  methods: {
    ...mapMutations({
      setNews: 'article/SET_ARTICLES',
    }),
    ...mapActions({
      getNews: 'article/getArticles',
      getNewsHL: 'article/getArticlesHL',
      getCategory: 'article/getCategory',
    }),
    
    async getInitData(categoryId, newsId) {
      this.isLoading = true
      await this.fnGetNews(categoryId, newsId)
      this.isLoading = false
    },
    
    async showDetailNews(newsId) {
      this.$router.push({
        name: 'newsDetail',
        params: { newsId: newsId }
      });
    },
    async onPageChange(params) {
      this.isLoading = true
      this.currentPage = params.currentPage
      const objSend = {
        categoryId: this.categoryId || null,
        search: '',
        status: 1,
        currentPage: this.currentPage,
        itemsPerPage: this.perPage,
        organizationId: this.user.orgId
      }
      await this.getNews(objSend)
      this.isLoading = false
    },
    async fnGetNews(categoryId, newsId = 0) {
      this.isLoading = true
      if ( newsId ) {
        this.showDetail = true
        this.newsId = parseInt(newsId)
      } else {
        this.showDetail = false
      }
      this.currentPage = 1
      this.categoryId = parseInt(categoryId)
      const objSend = {
        categoryId: categoryId || null,
        search: '',
        status: 1,
        currentPage: this.currentPage,
        itemsPerPage: this.perPage,
        organizationId: this.user.orgId
      }
      await this.getNews(objSend)
      this.isLoading = false
    },
    
  },
}
</script>

<style lang="scss">
.title-news {
  color: black;
  font-weight: 500;
  cursor: pointer;
}

.title-continue {
  color: black;
}

.widget .wtitle {
  margin: 16px 0 12px;
  position: relative;
  font-size: 20px;
  display: inline-block;
  line-height: normal;
}

.widget .wtitle:after {
  content: "";
  height: 3px;
  background: #288cc4;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -5px;
}

.widget .wcontent a {
  display: block;
  color: #4f4f4f;
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 5px;
}

.ul-tintuc {
  padding: 0px 15px;
}

.ul-tintuc .li-tintuc {
  padding-bottom: 5px;
  
}

.ul-tintuc .li-tintuc::marker {
  padding-bottom: 5px;
}

.pagi ul {
  display: inline-flex;
}

.news-list-item {
  max-height: 200px;
}

.news-list-item .card-img {
  height: calc(100%);
}

.news-detail .news-description img {
  width: 100%;
}
</style>
