export const RoleCode = Object.freeze({
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  ADMIN: 'ADMIN',
})

export const PermissionCode = Object.freeze({
  MANAGE: 'manage',
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  PRINT: 'PRINT',
  FETCH: 'FETCH',
})

export const ResourceCode = Object.freeze({
  ALL: 'all',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  AUTH: 'AUTH',
  ADMISSION_DASHBOARD: 'ADMISSION_DASHBOARD',
  ADMISSION_INFO: 'ADMISSION_INFO',
  ADMISSION_APPLICANT_STUDENT: 'ADMISSION_APPLICANT_STUDENT',
  ADMISSION_EXAM_SCHEDULE: 'ADMISSION_EXAM_SCHEDULE',
  ADMISSION_EXAM_SCORE: 'ADMISSION_EXAM_SCORE',
  ADMISSION_ASPIRATION_INFORMATION: 'ADMISSION_ASPIRATION_INFORMATION',
  ACADEMIC_TRANSCRIPT_SCORE: 'ACADEMIC_TRANSCRIPT_SCORE',
  ADMISSION_THPTQG_EXAM_SCORE: 'ADMISSION_THPTQG_EXAM_SCORE',
  REGISTER_TEST: 'REGISTER_TEST',
  EXCHANGE_SCORE: 'EXCHANGE_SCORE',
  ADMISSION_SCORE: 'ADMISSION_SCORE',
  ADMISSION_CERTIFICATIONS: 'ADMISSION_CERTIFICATIONS',
})

export default { RoleCode, PermissionCode, ResourceCode }
