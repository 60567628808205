import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/authentication/login',
    name: 'login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: ResourceCode.AUTH,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/authentication/notAuthorized',
    name: 'notAuthorized',
    component: () => import('@/views/authorization/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: ResourceCode.AUTH,
    },
  },
  {
    path: '/authentication/forgotPassword',
    name: 'forgotPassword',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: ResourceCode.AUTH,
    },
  },
  {
    path: '/authentication/verifyOTP',
    name: 'verifyOTP',
    component: () => import('@/views/authentication/VerifyOTP.vue'),
    meta: {
      layout: 'full',
      resource: ResourceCode.AUTH,
    },
  },
  {
    path: '/authentication/resetPassword',
    name: 'resetPassword',
    component: () => import('@/views/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: ResourceCode.AUTH,
    },
  },
  {
    path: '/change_password',
    name: 'changePassword',
    component: () => import('@/views/authentication/ChangePassword.vue'),
    meta: {
      pageTitle: 'Thay đổi mật khẩu',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Thay đổi mật khẩu',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CHANGE_PASSWORD,
    },
  },
  {
    path: '/auth/login/ssoCallBack',
    name: 'LoginSsoCallBack',
    component: () => import('@/views/authentication/LoginSsoCallBack.vue'),
    meta: {
      layout: 'full',
      resource: ResourceCode.AUTH,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/logout/ssoCallBack',
    name: 'LogoutSsoCallBack',
    component: () => import('@/views/authentication/LogoutSsoCallBack.vue'),
    meta: {
      layout: 'full',
      resource: ResourceCode.AUTH,
    },
  },
  {
    path: '/authentication/registerAccount',
    name: 'registerAccount',
    component: () => import('@/views/authentication/RegisterAccount.vue'),
    meta: {
      layout: 'full',
      resource: ResourceCode.AUTH,
    },
  },
]
