export const STATUSES = [
  { value: 1, label: 'Hoạt động' },
  { value: 0, label: 'Không hoạt động' },
]

export const MENU_TYPES = [
  { value: 1, label: 'Quản trị' },
  { value: 2, label: 'Portal' },
]
export const RANK_CERTIFICATE_TYPES = [
  { value: 1, label: 'NHẤT' },
  { value: 2, label: 'NHÌ' },
  { value: 3, label: 'BA' },
  { value: 4, label: 'KHUYẾN KHÍCH/TƯ' },
]

export const ADMISSION_CONFIRM_CERTIFICATE_STATUSES = [
  { value: -1, label: 'Không chấp nhận' },
  { value: 0, label: 'Chờ xác nhận' },
  { value: 1, label: 'Chấp nhận' },
]
