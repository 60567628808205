<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      body-class="position-static"
      centered
      title='Thêm quy đổi điểm'
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="admissionFormId">
              <template v-slot:label>
                Phương thức tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Phương thức tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionFormId"
                  :options="dataListAdmissionForm"
                  :reduce="option => option.value"
                  @input="selectAdmissionForm"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="admissionLanguageCertificateId">
              <template v-slot:label>
                Chứng chỉ ngoại ngữ quy đổi <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chứng chỉ ngoại ngữ quy đổi"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionLanguageCertificateId"
                  :options="admissionLanguageCertificateByAdmissionFormIds"
                  :reduce="option => option.value"
                  @input="selectAdmissionLanguageCertificate"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="admissionScoreTypeId">
              <template v-slot:label>
                Loại điểm quy đổi <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Loại điểm quy đổi"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionScoreTypeId"
                  :options="dataAdmissionScores"
                  :reduce="option => option.value"
                  @input="selectAdmissionScoreType"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="admissionSubjectId">
              <template v-slot:label>
                Môn học quy đổi điểm <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Môn học quy đổi điểm"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionSubjectId"
                  label="label"
                  :options="admissionSubjectIds"
                  :reduce="option => option.value"
                  @input="selectAdmissionSubject"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="scoreCertificate">
              <template v-slot:label>
                Điểm chứng chỉ <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Điểm chứng chỉ"
                rules="required"
              >
                <b-form-input
                  id="scoreCertificate"
                  v-model="targetItem.scoreCertificate"
                  disabled
                  name="scoreCertificate"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="scoreByExchange">
              <template v-slot:label>
                Điểm quy đổi <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Điểm quy đổi"
                rules="required"
              >
                <b-form-input
                  id="scoreByExchange"
                  v-model="targetItem.scoreByExchange"
                  disabled
                  name="scoreByExchange"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'

export default {
  name: 'Save',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        id: null,
        admissionRoundId: getUser().admissionRoundId,
        admissionFormId: null,
        applicantStudentId: getUser().id,
        admissionScoreTypeId: null,
        admissionLanguageCertificateId: null,
        admissionSubjectId: null,
        scoreCertificate: null,
        exchangeScore: null,
        scoreByExchange: null,
        score: null,
        admissionPriorityScoreId: null,
        status: 1,
      },
      scoreSource: [],
      check: true,
      required,
    }
  },
  computed: {
    ...mapGetters({
      dataListAdmissionForm: 'applicantStudent/dataListAdmissionForm',
      admissionLanguageCertificateByAdmissionFormIds: 'exchangeScore/admissionLanguageCertificateByAdmissionFormIds',
      admissionSubjectIds: 'exchangeScore/admissionSubjectIds',
      scoreCertificate: 'exchangeScore/scoreCertificate',
      exchangeScoreCertificate: 'exchangeScore/exchangeScoreCertificate',
      dataAdmissionScores: 'exchangeScore/admissionScoreTypes',
      academicTranscriptScores: 'exchangeScore/academicTranscriptScores',
    }),
  },
  methods: {
    ...mapActions({
      getDataListAdmissionForm: 'applicantStudent/getDataListAdmissionForm',
      getAdmissionLanguageCertificates: 'exchangeScore/getAdmissionLanguageCertificates',
      getAdmissionSubjects: 'exchangeScore/getAdmissionSubjects',
      getScoreCertificate: 'exchangeScore/getScoreCertificate',
      getExchangeScore: 'exchangeScore/getExchangeScore',
      updateScore: 'exchangeScore/update',
      createScore: 'exchangeScore/create',
      getAdmissionScoreTypes: 'exchangeScore/getAdmissionScoreTypes',
      getAcademicTranscriptScore: 'exchangeScore/getAcademicTranscriptScore',
      checkAcademicTranscriptScore: 'exchangeScore/checkAcademicTranscriptScore',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async selectAdmissionForm() {
      await this.getAdmissionLanguageCertificates({
        admissionRoundId: getUser().admissionRoundId,
        admissionFormId: this.targetItem.admissionFormId,
      })
    },
    async selectAdmissionLanguageCertificate() {
      await Promise.all([
        this.getAdmissionSubjects({
          admissionRoundId: getUser().admissionRoundId,
          admissionFormId: this.targetItem.admissionFormId,
          admissionLanguageCertificateId: this.targetItem.admissionLanguageCertificateId,
        }),
        this.getScoreCertificate({
          applicantStudentId: getUser().id,
          admissionLanguageCertificateId: this.targetItem.admissionLanguageCertificateId,
        })
      ]);
      this.targetItem.scoreCertificate = this.scoreCertificate
    },
    async selectAdmissionSubject() {
      this.targetItem.scoreByExchange = null
      this.targetItem.id = null
      await this.getExchangeScore({
        admissionRoundId: getUser().admissionRoundId,
        admissionFormId: this.targetItem.admissionFormId,
        admissionLanguageCertificateId: this.targetItem.admissionLanguageCertificateId,
        admissionSubjectId: this.targetItem.admissionSubjectId,
        admissionScoreTypeId: this.targetItem.admissionScoreTypeId,
        scoreCertificate: this.targetItem.scoreCertificate
      })
      await this.getAcademicTranscriptScore({
        applicantStudentId: getUser().id,
        admissionSubjectId: this.targetItem.admissionSubjectId,
        admissionScoreTypeId: this.targetItem.admissionScoreTypeId,
      })
      this.targetItem.scoreByExchange = this.exchangeScoreCertificate[0].exchangeScore
      this.targetItem.admissionPriorityScoreId = this.exchangeScoreCertificate[0].admissionPriorityScoreId
      this.check = await this.checkAcademicTranscriptScore({
        applicantStudentId: getUser().id,
        admissionSubjectId: this.targetItem.admissionSubjectId,
        admissionScoreTypeId: this.targetItem.admissionScoreTypeId,
      })
      if (!this.check) {
        this.targetItem.id = this.academicTranscriptScores[0].id
        this.targetItem.score = this.academicTranscriptScores[0].score
      }
    },
    async selectAdmissionScoreType() {
      this.targetItem.scoreByExchange = null
      this.targetItem.id = null
      await this.getExchangeScore({
        admissionRoundId: getUser().admissionRoundId,
        admissionFormId: this.targetItem.admissionFormId,
        admissionLanguageCertificateId: this.targetItem.admissionLanguageCertificateId,
        admissionSubjectId: this.targetItem.admissionSubjectId,
        admissionScoreTypeId: this.targetItem.admissionScoreTypeId,
        scoreCertificate: this.targetItem.scoreCertificate
      })
      await this.checkAcademicTranscriptScore({
        applicantStudentId: getUser().id,
        admissionSubjectId: this.targetItem.admissionSubjectId,
        admissionScoreTypeId: this.targetItem.admissionScoreTypeId,
      })
      this.targetItem.scoreByExchange = this.exchangeScoreCertificate[0].exchangeScore
      this.targetItem.admissionPriorityScoreId = this.exchangeScoreCertificate[0].admissionPriorityScoreId
      this.check = await this.getAcademicTranscriptScore({
        applicantStudentId: getUser().id,
        admissionSubjectId: this.targetItem.admissionSubjectId,
        admissionScoreTypeId: this.targetItem.admissionScoreTypeId,
      })
      if (!this.check) {
        this.targetItem.id = this.academicTranscriptScores[0].id
        this.targetItem.score = this.academicTranscriptScores[0].score
      }
    },
    async onShow() {
      await this.getAdmissionScoreTypes()
      await this.getDataListAdmissionForm({ admissionRoundId: this.targetItem.admissionRoundId })
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        id: null,
        admissionRoundId: getUser().admissionRoundId,
        admissionFormId: null,
        applicantStudentId: getUser().id,
        admissionScoreTypeId: null,
        admissionLanguageCertificateId: null,
        admissionSubjectId: null,
        scoreCertificate: null,
        exchangeScore: null,
        scoreByExchange: null,
        score: null,
        admissionPriorityScoreId: null,
        status: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if ( valid ) {
        this.isLoading = true
        try {
          this.scoreSource = []
          this.targetItem.exchangeScore = this.targetItem.scoreByExchange
          this.scoreSource.push(this.targetItem)
          const response = this.check ?
            await this.createScore(this.scoreSource)
            : await this.updateScore(this.targetItem)
          if ( response ) {
            const {
              isSuccessful,
              message
            } = response
            if ( isSuccessful ) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if ( type === 'hide' ) {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch ( e ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
