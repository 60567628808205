import { ResourceCode } from '@/const/code'

export default [
  {
    path: '/score/admissionScore',
    name: 'admissionScore',
    component: () => import('@/views/admission-score/AdmissionScore.vue'),
    meta: {
      pageTitle: 'Điểm xét tuyển',
      breadcrumb: [
        {
          text: 'Thông tin xét tuyển',
          active: true,
        },
        {
          text: 'Điểm xét tuyển',
          active: false,
        },
      ],
      action: 'READ',
      resource: ResourceCode.ADMISSION_SCORE,
    },
  },
]
