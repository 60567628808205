<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.saveModal
                    :disabled="!checkRegisterDate"
                    variant="success"
                    @click="onCreate"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="PlusIcon" /> Quy đổi điểm
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="dataSources"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  @on-page-change="onPageChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'stt'">
                      {{ props.row.originalIndex + 1 }}
                    </span>
                    <span v-if="props.column.field === 'status'">
                      {{ props.row.status === 1 ? 'Đang hoạt động' : 'Không hoạt động' }}
                    </span>
                    <span v-else-if="props.column.field === 'download'">
                      <b-img thumbnail fluid :src="props.row.url" alt="Image 1"></b-img>
                    </span>
                    <span v-else-if="props.column.field === 'externalLink'">
                       <b-link target="_blank" :href="props.row.externalLink" >{{props.row.externalLink}}</b-link>
                    </span>
                    <span v-else-if="props.column.field === 'action'">
                      <b-button
                        v-if="deletable"
                        :disabled="!checkRegisterDate"
                        variant="danger"
                        class="btn-icon"
                        style="margin-left: 6px"
                        size="sm"
                        @click="onDelete(props.row)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>
                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <Save
      @succeed="onSucceed"
    />
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormSelect, BImg, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import Save from '@/views/exchange-score/Save.vue'
import { getUser } from '@/auth/utils'

export default {
  name: 'Carouse',
  directives: {
    Ripple,
  },
  components: {
    BImg,
    Save,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      currentUser: getUser(),
      filter: {
        admissionRoundId: getUser().admissionRoundId,
        applicantStudentId: getUser().id,
        currentPage: 1,
        itemsPerPage: 10,
        status: 1,
      },
      checkRegisterDate: false,
      currentDataSource: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'exchangeScore/admissionExchangeScores',
      totalRows: 'exchangeScore/totalRows',
    }),
    columns() {
      return [
        {
          label: 'Loại điểm',
          field: 'admissionScoreTypeName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Môn học quy đổi điểm',
          field: 'admissionSubjectName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm trước quy đổi',
          field: 'score',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm sau quy đổi',
          field: 'exchangeScore',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.EXCHANGE_SCORE)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.EXCHANGE_SCORE)
    },
  },
  watch: {
    async resource() {
      await this.onLoad()
    },
  },
  async created() {
    await this.onLoad()
  },
  methods: {
    ...mapMutations({
      setDataSources: 'exchangeScore/SET_ADMISSION_REGISTER_TESTS',
    }),
    ...mapActions({
      getDataSources: 'exchangeScore/getAdmissionExchangeScores',
      deleteDataSource: 'exchangeScore/delete',
      checkDateAdmissionRound: 'account/checkDateAdmissionRound',
    }),
    async onLoad() {
      this.isLoading = true
      this.checkRegisterDate = this.checkDateAdmissionRound(this.currentUser.admissionRoundId)
      try {
        await this.getDataSourcesFromStore()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.setDataSources([])
      } finally {
        this.isLoading = false
      }
    },
    onCreate() {
      this.currentDataSource = undefined
    },
    onDelete(dataSource) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa điểm quy đổi <span class="text-danger">${dataSource.admissionSubjectName}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteDataSource(dataSource.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getDataSourcesFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getDataSources(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getDataSourcesFromStore()
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
    },
    getStatusName(id) {
      return this.status.find(status => status.value === id).label
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table';
</style>
