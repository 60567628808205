<template>
  <div>
    <b-col>
      <h4 class="mt-1 mb-2">
        1. Điểm xét tuyển theo nguyện vọng ứng tuyển
      </h4>
      <b-card-code
        no-body
        title="Danh sách nguyện vọng:"
      >
        <b-table
          responsive="sm"
          :items="items"
          style="text-align: center;"
        />
      </b-card-code>
      <h4 class="mt-1 mb-2">
        2. Kết quả xét tuyển
      </h4>
      <h4
        class="mt-1 mb-2"
      >
        Thí sinh trúng tuyển đã trúng tuyển vào ngành: <span style="color: red;">{{ admissionMajorNameShow }}</span>
        theo phương thức: <span style="color: red;">{{ admissionFormNameShow }}</span>
        với điểm xét tuyển là: <span style="color: red;">{{ scoreShow }}</span>
      </h4>
      
      <h4
        class="mt-1 mb-2"
      >
        Thí sinh tiến hành xác nhận nhập học theo đúng thời gian quy định.
      </h4>
      <span>
        <b-button
          v-show="confirmAdmissionButton"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="acceptAspirationInformation()"
        >
          <feather-icon
            icon="CreditCardIcon"
            class=""
          />
          <span> Xác nhận nhập học</span>
        </b-button>
      </span>
    </b-col>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BButton, BTable } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCardCode,
    BTable,
  },
  data() {
    return {
      items: [],
      user: getUser(),
      admissionMajorNameShow: '',
      admissionFormNameShow: '',
      scoreShow: '',
      confirmAdmissionButton: false
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'admissionScore/aspirationInformations',
    }),
  },
  async created() {
    await this.getInitData()
  },
  methods: {
    ...mapActions({
      getDataSource: 'admissionScore/getListAspirationInformation',
      confirmAdmission: 'admissionScore/confirmAdmission'
    }),
    transformData(data) {
      return data.map(item => ({
        Phương_thức_xét_tuyển: item.admissionFormName,
        Ngành_ứng_tuyển: item.majorName,
        Tổ_hợp_xét_tuyển: item.admissionBlockName,
        Điểm_xét_tuyển: item.admissionScore ? item.admissionScore : 'Chưa có điểm',
      }))
    },
    checkData(data) {
      return data.map(item => {
        if (item.accepted === 1) {
          this.admissionMajorNameShow = item.majorName
          this.admissionFormNameShow = item.admissionFormName
          this.scoreShow = item.admissionScore
          this.confirmAdmissionButton = true
        }
      })
    },
    async getInitData() {
      const params = {
        admissionRoundId: this.user.admissionRoundId,
        applicantStudentId: this.user.id,
      }
      await this.getDataSource(params)
      this.items = this.transformData(this.dataSources)
      this.checkData(this.dataSources)
    },
    acceptAspirationInformation() {
      this.$swal({
        title: `Bạn chắc chắn xác nhận nhập học ngành: <span style="color: red;">${ this.admissionMajorNameShow }</span> theo phương thức: <span style="color: red;">${ this.admissionFormNameShow }</span>?`,
        text: 'Bạn sẽ không thể hủy bỏ sau khi đã xác nhận nhập học!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.confirmAdmission(this.user.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
  },
}
</script>
