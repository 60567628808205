import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// eslint-disable-next-line import/no-cycle
import authentication from '@/store/modules/authentication'
import menu from '@/store/modules/authorization/menu'
import account from '@/store/modules/authorization/account'
import dashboard from '@/store/dashboard'
import applicantStudent from '@/store/applicant-student'
import aspiration from '@/store/aspiration'
import article from '@/store/article'
import admissionConfirmation from '@/store/admission-confirmation'
import admissionCertificate from '@/store/admission-certificate'
import admissionLanguageCertificate from '@/store/admission-language-certificate'
import admissionConductAndAcademicPerformance from '@/store/admission-conduct-and-academic-performance'
import academicTranscriptScore from '@/store/academic-transcript-score'
import examination from '@/store/examination'
import exchangeScore from '@/store/exchange-score'
import registerTest from '@/store/register-test'
import verticalMenu from './vertical-menu'
import appConfig from './app-config'
import app from './app'
import payment from '@/store/finance/payment'
import receipt from '@/store/finance/receipt'
import admissionScore from '@/store/admission-score'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    authentication,
    menu,
    account,
    dashboard,
    applicantStudent,
    aspiration,
    article,
    admissionConfirmation,
    admissionCertificate,
    admissionLanguageCertificate,
    admissionConductAndAcademicPerformance,
    academicTranscriptScore,
    registerTest,
    payment,
    receipt,
    examination,
    exchangeScore,
    admissionScore,
  },
  strict: process.env.DEV,
})
