<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <div>
      <b-form class="mt-1 mb-1">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="2"
          >
            <b-form-group
              label="Năm tốt nghiệp THPT"
              label-for="graduatedYear"
            >
              <template v-slot:label>
                Năm tốt nghiệp THPT <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Năm tốt nghiệp THPT"
                rules="required"
              >
                <b-form-input
                  id="graduatedYear"
                  v-model="userData.graduatedYear"
                  type="number"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Khu vực ưu tiên"
              label-for="factPriorityZoneId"
            >
              <v-select
                id="factPriorityZoneId"
                v-model="userData.factPriorityZoneId"
                label="label"
                :options="dataListPriorityZones"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Đối tượng ưu tiên"
              label-for="factAdmissionConfirmationTypeId"
            >
              <v-select
                id="factAdmissionConfirmationTypeId"
                v-model="userData.factAdmissionConfirmationTypeId"
                label="label"
                :options="dataListConfirmationType"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Ảnh chụp giấy chứng nhận đối tượng ưu tiên"
              label-for="file"
            >
              <b-form-file
                ref="inputFileConfirmationRef"
                v-model = "userData.confirmationMediaId"
                :placeholder="userData.confirmationMediaName"
                accept="image/*"
                @change="uploadConfirmation"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <div class="d-flex">
        <feather-icon
          icon="PaperclipIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Thông tin về quá trình học tập tại trường THPT (học lực, hạnh kiểm)
        </h4>
      </div>
      <b-form class="mt-1">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <div class="d-flex mt-1">
              <h6 class="mb-1" style="color: #7367f0">
                Lớp 10
              </h6>
            </div>
            <b-form-group
              label="Học lực"
              label-for="factAcademicPerformanceTenth"
            >
              <template v-slot:label>
                Học lực <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Học lực"
                rules="required"
              >
                <v-select
                  v-model="userData.factAcademicPerformanceTenth"
                  :options="academicPerformances"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Hạnh kiểm"
              label-for="factAdmissionConductTenth"
            >
              <template v-slot:label>
                Học kiểm <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Học kiểm"
                rules="required"
              >
                <v-select
                  v-model="userData.factAdmissionConductTenth"
                  :options="admissionConducts"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <div class="d-flex mt-1">
              <h6 class="mb-1" style="color: #7367f0">
                Lớp 11
              </h6>
            </div>
            <b-form-group
              label="Học lực"
              label-for="factAcademicPerformanceEleventh"
            >
              <template v-slot:label>
                Học lực <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Học lực"
                rules="required"
              >
                <v-select
                  v-model="userData.factAcademicPerformanceEleventh"
                  :options="academicPerformances"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Hạnh kiểm"
              label-for="factAdmissionConductEleventh"
            >
              <template v-slot:label>
                Học kiểm <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Học kiểm"
                rules="required"
              >
                <v-select
                  v-model="userData.factAdmissionConductEleventh"
                  :options="admissionConducts"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <div class="d-flex mt-1">
              <h6 class="mb-1" style="color: #7367f0">
                Lớp 12
              </h6>
            </div>
            <b-form-group
              label="Học lực"
              label-for="factAcademicPerformanceTwelfth"
            >
              <template v-slot:label>
                Học lực <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Học lực"
                rules="required"
              >
                <v-select
                  v-model="userData.factAcademicPerformanceTwelfth"
                  :options="academicPerformances"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Hạnh kiểm"
              label-for="factAdmissionConductTwelfth"
            >
              <template v-slot:label>
                Học kiểm <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Học kiểm"
                rules="required"
              >
                <v-select
                  v-model="userData.factAdmissionConductTwelfth"
                  :options="admissionConducts"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-1">
          <feather-icon
            icon="BookIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Kết quả học tập Trung bình chung (TBC) các năm học THPT
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="TBC Lớp 10"
              label-for="gpaTenth"
            >
              <template v-slot:label>
                TBC Lớp 10 <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="TBC Lớp 10"
                rules="required"
              >
                <b-form-input
                  id="gpaTenth"
                  v-model="userData.gpaTenth"
                  type="number"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="TBC Lớp 11"
              label-for="gpaEleventh"
            >
              <template v-slot:label>
                TBC Lớp 11 <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="TBC Lớp 11"
                rules="required"
              >
                <b-form-input
                  id="gpaEleventh"
                  v-model="userData.gpaEleventh"
                  type="number"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="TBC Lớp 12"
              label-for="gpaTwelfth"
            >
              <template v-slot:label>
                TBC Lớp 12 <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="TBC Lớp 12"
                rules="required"
              >
                <b-form-input
                  id="gpaTwelfth"
                  v-model="userData.gpaTwelfth"
                  type="number"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2">
          <feather-icon
            icon="AwardIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Thành tích học tập (Chỉ nhập thành tích cao nhất)
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Loại chứng chỉ học tập"
              label-for="factAdmissionCertificateTypeId"
            >
              <v-select
                id="factAdmissionCertificateTypeId"
                v-model="userData.factAdmissionCertificateTypeId"
                label="label"
                :options="dataListCertificateType"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Môn thi"
              label-for="subjectCertificateId"
            >
              <v-select
                id="subjectCertificateId"
                v-model="userData.subjectCertificateId"
                label="label"
                :options="dataListSubject"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Hạng giải"
              label-for="rankCertificate"
            >
              <v-select
                id="rankCertificate"
                v-model="userData.rankCertificate"
                label="label"
                :options="RANK_CERTIFICATE_TYPES"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Năm đạt giải"
              label-for="yearCertificate"
            >
              <b-form-input
                id="yearCertificate"
                v-model="userData.yearCertificate"
                :placeholder="userData.yearCertificate"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="8"
          >
            <b-form-group
              label="Ảnh chụp giấy chứng nhận"
              label-for="file"
            >
              <b-form-file
                ref="inputFileCertificateRef"
                v-model = "userData.certificateMediaId"
                :placeholder="userData.certificateMediaName"
                accept="image/*"
                @change="uploadCertificate"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2">
          <feather-icon
            icon="GlobeIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Chứng chỉ ngoại ngữ (Chỉ nhập chứng chỉ ngoại ngữ có điểm cao nhất)
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Loại chứng chỉ ngoại ngữ"
              label-for="factAdmissionLanguageCertificateTypeId"
            >
              <v-select
                id="factAdmissionLanguageCertificateTypeId"
                v-model="userData.factAdmissionLanguageCertificateTypeId"
                label="label"
                :options="dataListLanguageCertificateType"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Tổng Điểm thi"
              label-for="scoreLanguageCertificate"
            >
              <b-form-input
                id="scoreLanguageCertificate"
                v-model="userData.scoreLanguageCertificate"
                :placeholder="userData.scoreLanguageCertificate"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Ngày thi"
              label-for="dateLanguageCertificate"
            >
              <flat-pickr
                v-model="userData.dateLanguageCertificate"
                class="form-control"
                :config="{ dateFormat: 'd-m-Y'}"
                placeholder="DD-MM-YYYY"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="12"
          >
            <b-form-group
              label="Ảnh chụp chứng chỉ ngoại ngữ"
              label-for="file"
            >
              <b-form-file
                ref="inputFileLanguageCertificateRef"
                v-model = "userData.languageCertificateMediaId"
                :placeholder="userData.languageCertificateMediaName"
                accept="image/*"
                @change="uploadLanguageCertificate"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-button
        :disabled="!checkRegisterDate"
        type="submit"
        variant="primary"
        class="mr-1"
        @click="onSave"
      >
        Lưu
      </b-button>
    </div>
  </validation-observer>
</template>

<script>

import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormFile, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/named
import { RANK_CERTIFICATE_TYPES } from '@/const/status'
import flatPickr from 'vue-flatpickr-component'
import { Flag } from '@/const/flag'
import { getUser } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BFormFile,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      currentUser: getUser(),
      RANK_CERTIFICATE_TYPES,
      checkRegisterDate: false,
    }
  },
  computed: {
    ...mapGetters({
      dataListConfirmationType: 'applicantStudent/dataListConfirmationType',
      dataListPriorityZones: 'applicantStudent/dataListPriorityZones',
      admissionConducts: 'applicantStudent/admissionConducts',
      academicPerformances: 'applicantStudent/academicPerformances',
      dataListCertificateType: 'applicantStudent/dataListCertificateType',
      dataListSubject: 'applicantStudent/dataListSubject',
      checkRegister: 'account/checkRegister',
      dataListLanguageCertificateType: 'applicantStudent/dataListLanguageCertificateType',
    }),
  },
  async created() {
    await this.onLoad()
  },
  methods: {
    ...mapActions({
      getListConfirmationType: 'applicantStudent/getListConfirmationType',
      getListPriorityZones: 'applicantStudent/getListPriorityZones',
      getAdmissionConducts: 'applicantStudent/getAdmissionConducts',
      getAcademicPerformances: 'applicantStudent/getAcademicPerformances',
      getDataCertificateType: 'applicantStudent/getCertificateType',
      getListSubject: 'applicantStudent/getListSubject',
      getLanguageCertificateType: 'applicantStudent/getLanguageCertificateType',
      updateApplicantStudent: 'applicantStudent/updateApplicantStudent',
      uploadConfirmationFile: 'admissionConfirmation/uploadApplicantStudentConfirmation',
      createConfirmation: 'admissionConfirmation/create',
      uploadCertificateFile: 'admissionCertificate/uploadApplicantStudentCertificate',
      createCertificate: 'admissionCertificate/create',
      uploadLanguageCertificateFile: 'admissionLanguageCertificate/uploadApplicantStudentLanguageCertificate',
      createLanguageCertificate: 'admissionLanguageCertificate/create',
      createConductAndAcademicPerformance: 'admissionConductAndAcademicPerformance/create',
      getDataHighSchoolById: 'applicantStudent/getDataHighSchoolById',
      checkDateAdmissionRound: 'account/checkDateAdmissionRound',
    }),
    async onLoad() {
      this.isLoading = true
      try {
        await Promise.all([
          this.getListConfirmationType({}),
          this.getListPriorityZones(),
          this.getAcademicPerformances(),
          this.getAdmissionConducts(),
          this.getDataCertificateType({}),
          this.getLanguageCertificateType({}),
          this.getListSubject({}),
          this.checkDateAdmissionRound(this.currentUser.admissionRoundId),
        ])
        this.checkRegisterDate = this.checkRegister
        if (this.userData.highSchoolTwelfth) {
          const data = await this.getDataHighSchoolById({ id: this.userData.highSchoolTwelfth })
          if (data) {
            this.userData.factPriorityZoneId = data.priorityZoneCode
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
    async uploadConfirmation(e) {
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ảnh đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const countFileOverSize = this.checkMaxSizeUpload(files)
      if (countFileOverSize > 0) return
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      Array.from(files).forEach(file => {
        if (!allowedTypes.includes(file.type)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Chọn file có định dạng ảnh: JPEG, PNG, GIF, ...',
              icon: 'InfoIcon',
              variant: 'warning',
            },
          });
          return;
        }
        formData.append('files', file);
      });
      await this.$recaptchaLoaded()
      const recaptchaResponse = await this.$recaptcha('uploadConfirmationFile')
      formData.append('recaptchaResponse', recaptchaResponse)
      const params = {
        id: this.userData.id,
        params: formData,
      }
      const response = await this.uploadConfirmationFile(params)
      if (response.isSuccessful) {
        this.showToast('Cập nhật thành công', 'CheckIcon', 'success')
        this.mediaFrontIds = response
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
    },
    async uploadCertificate(e) {
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ảnh đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const countFileOverSize = this.checkMaxSizeUpload(files)
      if (countFileOverSize > 0) return
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      Array.from(files).forEach(file => {
        if (!allowedTypes.includes(file.type)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Chọn file có định dạng ảnh: JPEG, PNG, GIF, ...',
              icon: 'InfoIcon',
              variant: 'warning',
            },
          });
          return;
        }
        formData.append('files', file);
      });
      await this.$recaptchaLoaded()
      const recaptchaResponse = await this.$recaptcha('uploadCertificateFile')
      formData.append('recaptchaResponse', recaptchaResponse)
      const params = {
        id: this.userData.id,
        params: formData,
      }
      const response = await this.uploadCertificateFile(params)
      if (response.isSuccessful) {
        this.showToast('Cập nhật thành công', 'CheckIcon', 'success')
        this.mediaFrontIds = response
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
    },
    async uploadLanguageCertificate(e) {
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ảnh đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const countFileOverSize = this.checkMaxSizeUpload(files)
      if (countFileOverSize > 0) return
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      Array.from(files).forEach(file => {
        if (!allowedTypes.includes(file.type)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Chọn file có định dạng ảnh: JPEG, PNG, GIF, ...',
              icon: 'InfoIcon',
              variant: 'warning',
            },
          });
          return;
        }
        formData.append('files', file);
      });
      await this.$recaptchaLoaded()
      const recaptchaResponse = await this.$recaptcha('uploadLanguageCertificateFile')
      formData.append('recaptchaResponse', recaptchaResponse)
      const params = {
        id: this.userData.id,
        params: formData,
      }
      const response = await this.uploadLanguageCertificateFile(params)
      if (response.isSuccessful) {
        this.showToast('Cập nhật thành công', 'CheckIcon', 'success')
        this.mediaFrontIds = response
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
    },

    checkMaxSizeUpload(files) {
      let listOverSize = 0
      files.forEach(file => {
        if (file.size > 0.5 * 1024 * 1024) {
          listOverSize++
        }
      })

      if (listOverSize > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Dung lượng vượt quá 0.5M',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
      }
      return listOverSize
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onSave() {
      this.isLoading = true
      const tempConfirmation = {
        applicantStudentId: this.userData.id,
        factAdmissionConfirmationTypeId: this.userData.factAdmissionConfirmationTypeId,
        status: Flag.ACTIVE
      }
      const tempCertificate = {
        applicantStudentId: this.userData.id,
        factAdmissionCertificateTypeId: this.userData.factAdmissionCertificateTypeId,
        subjectCertificateId: this.userData.subjectCertificateId,
        rankCertificate: this.userData.rankCertificate,
        yearCertificate: this.userData.yearCertificate,
        status: Flag.ACTIVE
      }
      const tempLanguageCertificate = {
        applicantStudentId: this.userData.id,
        factAdmissionLanguageCertificateTypeId: this.userData.factAdmissionLanguageCertificateTypeId,
        scoreLanguageCertificate: this.userData.scoreLanguageCertificate,
        dateLanguageCertificate: this.userData.dateLanguageCertificate,
        status: Flag.ACTIVE
      }
      const tempConductAndAcademicPerformance = {
        applicantStudentId: this.userData.id,
        factAcademicPerformanceTenth: this.userData.factAcademicPerformanceTenth,
        factAdmissionConductTenth: this.userData.factAdmissionConductTenth,
        factAcademicPerformanceEleventh: this.userData.factAcademicPerformanceEleventh,
        factAdmissionConductEleventh: this.userData.factAdmissionConductEleventh,
        factAcademicPerformanceTwelfth: this.userData.factAcademicPerformanceTwelfth,
        factAdmissionConductTwelfth: this.userData.factAdmissionConductTwelfth,
        gpaTenth: this.userData.gpaTenth,
        gpaEleventh: this.userData.gpaEleventh,
        gpaTwelfth: this.userData.gpaTwelfth,
        status: Flag.ACTIVE
      }
      try {
        await Promise.all([
          this.createConfirmation(tempConfirmation),
          this.createCertificate(tempCertificate),
          this.createLanguageCertificate(tempLanguageCertificate),
          this.createConductAndAcademicPerformance(tempConductAndAcademicPerformance),
        ])
        const response = await this.updateApplicantStudent(this.userData)
        if (response) {
          const { isSuccessful } = response
          if (isSuccessful) {
            this.showToast('Cập nhật thành công', 'CheckIcon', 'success')
          } else {
            this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
