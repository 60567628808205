<template>
  <b-card title="Các lưu ý khi đăng ký xét tuyển">
    <app-timeline>
      <app-timeline-item>
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Khai báo thông tin cá nhân</h6>
        </div>
        <p>Thí sinh kê khai đầy đủ thông tin theo đúng hướng dẫn của hệ thống.<br>
          Yêu cầu:<br>
          - Kê khai thông tin cá nhân và thông tin gia đình đúng theo thực tế<br>
          - Kê khai địa chỉ đúng theo thông tin ghi trên căn cước công dân<br>
          - Kê khai số điện thoại của cá nhân, số điện thoại liên hệ</p>
      </app-timeline-item>
      <app-timeline-item variant="warning">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Thông tin học tập</h6>
        </div>
        <p>
          - Thí sinh kê khai các thông tin chi tiết về quá trình học tập ở bậc trung học phổ thông theo đúng thông tin của học bạ, trong đó bắt buộc phải có điểm của môn Toán.<br>
          - Đối với Điểm từng môn, Thí sinh khai điểm ở thang điểm 10, làm tròn đến 1 chữ số thập phân, phân cách bởi dấu chấm.<br>
          - Thí sinh kê khai các loại Chứng chỉ quốc tế hoặc Giải quốc tế, quốc gia, giải tỉnh theo đúng form.<br>
          - Trường hợp có nhiều loại chứng chỉ hoặc giải thưởng, thí sinh chỉ kê khai loại chứng chỉ/giải thưởng cao nhất đã đạt.
        </p>
      </app-timeline-item>
      <app-timeline-item variant="success">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Đăng ký nguyện vọng</h6>
        </div>
        <p>Thí sinh chủ động lựa chọn Phương thức, Đối tượng và Tổ hợp, Ngành/Chương trình phù hợp với nguyện vọng cá nhân.</p>
        <p>Nhà trường không giới hạn số lượng nguyện vọng, phương thức, đối tượng và tổ hợp.</p>
        <p>Lưu ý:</p>
        <ul>
          <li>Các nguyện vọng có giá trị xét tuyển như nhau, nguyện vọng 1 là nguyện vọng có ưu tiên cao nhất.</li>
          <li>Thí sinh được xem xét, xét tuyển trực tiếp vào các chương trình và chỉ được cho trúng tuyển duy nhất 1 nguyện vọng (nếu đủ điều kiện).</li>
        </ul>
      </app-timeline-item>
      <app-timeline-item variant="info">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Đăng ký môn thi tuyển sinh (nếu có) & Thanh toán lệ phí</h6>
        </div>
        <p>
          - Thí sinh đăng ký môn thi tuyển trong đợt tuyển sinh và theo dõi lịch thi, phòng thi trong danh mục thông tin thi tuyển.<br>
          - Thí sinh tiến hành thanh toán lệ phí tuyển sinh trước khi cổng thanh toán bị đóng. Việc đóng lệ phí tuyển sinh là điều kiện bắt buộc để xét tuyển.
        </p>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
}
</script>

<style>

</style>
